import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import ContactForm from "../clients/forms/ContactForm";
import { PriceBookItemTypes } from "../core/utils/enums";
import { historyHasState, sendDataToServer, validateSendContact, valueIsDefined } from "../core/utils/utils";
import JobDetailsCard from "./components/JobDetailsCard";
import JobAttachmentForm from "./forms/JobAttachmentsForm";
import JobCancelForm from "./forms/JobCancelForm";
import JobPauseForm from "./forms/JobPauseForm";
import JobRescheduleForm from "./forms/JobRescheduleForm";
import JobSendForm from "./forms/JobSendForm";
import JobScheduleForm from "./forms/JobSheduleForm";
import JobStartForm from "./forms/JobStartForm";

const PAGE_MODES = {
    VIEW_JOB: "VIEW_JOB",
    SEND_JOB: "SEND_JOB",
    CANCEL_JOB: "CANCEL_JOB",
    PAUSE_JOB: "PAUSE_JOB",
    START_JOB: "START_JOB",
    RESUME_JOB: "RESUME_JOB",
    SCHEDULE_JOB: "SCHEDULE_JOB",
    RESCHEDULE_JOB: "RESCHEDULE_JOB",
    UNSCHEDULE_JOB: "UNSCHEDULE_JOB",
    UPDATE_ATTACHMENTS: "UPDATE_ATTACHMENTS",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
}

const PAGE_MODE_SUBTITLES = {
    VIEW_JOB: "Job Details",
    SEND_JOB: "Send Job Summary",
    CANCEL_JOB: "Cancel Job",
    PAUSE_JOB: "Pause Job",
    START_JOB: "Start Job",
    RESUME_JOB: "Resume Job",
    SCHEDULE_JOB: "Schedule Job",
    RESCHEDULE_JOB: "Reschedule Job",
    UNSCHEDULE_JOB: "Unschedule Job",
    UPDATE_ATTACHMENTS: "Update Job Attachments",
    ADD_CONTACT: "Add Contact",
    EDIT_CONTACT: "Edit Contact",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_JOB: "flex",
    SEND_JOB: "none",
    CANCEL_JOB: "none",
    PAUSE_JOB: "none",
    START_JOB: "none",
    RESUME_JOB: "none",
    SCHEDULE_JOB: "none",
    RESCHEDULE_JOB: "none",
    UNSCHEDULE_JOB: "none",
    UPDATE_ATTACHMENTS: "none",
    ADD_CONTACT: "none",
    EDIT_CONTACT: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_JOB]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.SEND_JOB,
    PAGE_MODES.CANCEL_JOB,
    PAGE_MODES.PAUSE_JOB,
    PAGE_MODES.START_JOB,
    PAGE_MODES.RESCHEDULE_JOB,
    PAGE_MODES.UPDATE_ATTACHMENTS,
    PAGE_MODES.ADD_CONTACT,
    PAGE_MODES.EDIT_CONTACT,
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_JOB: "jobData",
    SEND_JOB: "sendData",
    CANCEL_JOB: "cancelData",
    PAUSE_JOB: "pauseData",
    START_JOB: "startData",
    RESUME_JOB: "resumeData",
    SCHEDULE_JOB: "scheduleData",
    RESCHEDULE_JOB: "rescheduleData",
    UNSCHEDULE_JOB: "unscheduleData",
    UPDATE_ATTACHMENTS: "updateAttachmentsData",
    ADD_CONTACT: "contactData",
    EDIT_CONTACT: "contactData",
}

const CONTACT_PAGE_MODES = [
    PAGE_MODES.ADD_CONTACT,
    PAGE_MODES.EDIT_CONTACT,
]

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_JOB: "submittingJob",
    SEND_JOB: "submittingSend",
    CANCEL_JOB: "submittingCancel",
    PAUSE_JOB: "submittingPause",
    START_JOB: "submittingStart",
    RESUME_JOB: "submittingResume",
    SCHEDULE_JOB: "submittingSchedule",
    RESCHEDULE_JOB: "submittingReschedule",
    UNSCHEDULE_JOB: "submittingUnschedule",
    UPDATE_ATTACHMENTS: "submittingUpdateAttachments",
    ADD_CONTACT: "submittingContact",
    EDIT_CONTACT: "submittingContact",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_JOB: "job",
    SEND_JOB: "send",
    CANCEL_JOB: "cancel",
    PAUSE_JOB: "pause",
    START_JOB: "start",
    RESUMET_JOB: "resume",
    SCHEDULE_JOB: "schedule",
    RESCHEDULE_JOB: "reschedule",
    UNSCHEDULE_JOB: "reschedule",
    UPDATE_ATTACHMENTS: "updateAttachments",
    ADD_CONTACT: "contact",
    EDIT_CONTACT: "contact",
}


class JobDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_JOB
        this.addToastToQueue = this.props.addToastToQueue
        this.backDestination = this.props.backDestination

        this.workingTechnicianOptions = window.WORKING_TECHNICIANS || []
        this.defaultClientMessage = window.CURRENT_USER?.service_company?.job_default_client_message

        this.state = {
            jobData: null,
            jobChildEstimatesData: null,
            jobChildCallbacksData: null,
            jobChildInvoicesData: null,
            jobPurchaseOrdersData: null,

            sendData: {},
            cancelData: {},
            startData: {},
            pauseData: {},
            scheduleData: {},
            rescheduleData: {},
            unscheduleData: {},
            resumeData: {},
            markAsEstimatedData: {},
            markAsInvoicedData: {},
            attachments: [],
            contactData: {},

            selectedSendPhoneContacts: [],
            selectedSendEmailContacts: [],

            selectedSubStatus: null,

            errors: {
                job: {},
                send: {},
                cancel: {},
                start: {},
                pause: {},
                schedule: {},
                reschedule: {},
                unschedule: {},
                updateAttachments:{},
                resume: {},
                contact: {},
                markAsEstimated: {},
                markAsInvoiced: {},
                attachments: {}
            },

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            defaultMode: defaultMode,
            mode: defaultMode,

            sendViaSMS: false,
            sendViaEmail: false,
            inventoryUsed: false,

            clockInStatus: null,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.jobData === null) {
            let jobEndpoint

            if (window.USING_PUBLIC_URL === true) {
                jobEndpoint = window.PUBLIC_REST_URL
            }
            else {
                jobEndpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
            }

            const jobResponse = await fetch(jobEndpoint)
            const job = await jobResponse.json()

            const parts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
            let inventoryUsed = false

            if (parts.length && window.USING_PUBLIC_URL === false) {
                let params = new URLSearchParams()
                params.append("line_item_type", PriceBookItemTypes.part)
                params.append("track_inventory", true)
                params.append("confirmed", true)

                parts.forEach(part => params.append("description", part.description))

                const inventoryPartsEndpoint = DjangoUrls["pricebook:api-pricebookitem-search"](window.MARKETPLACE_ENTITY_SLUG) + `?${params}`
                const inventoryPartsResponse = await fetch(inventoryPartsEndpoint)
                const inventoryPartsResponseJSON = await inventoryPartsResponse.json()

                inventoryUsed = inventoryPartsResponseJSON.results.length !== 0
            }

            this.setState((state, props) => {
                let updatedState = state
                updatedState.jobData = job

                updatedState.attachments = deepcopy()(job.attachments)
                updatedState.labels = deepcopy()(job.labels)

                // Split line items into their respective lists
                updatedState.jobData.service_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                updatedState.jobData.parts = parts
                updatedState.jobData.other_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                updatedState.jobData.discounts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                updatedState.inventoryUsed = inventoryUsed

                return updatedState
            })

            const hasPurchaseOrdersEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_purchase_orders_enabled;
            const hasPurchaseOrdersListPermission = !(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.purchase_orders_list_permission >= PERMISSION_LEVEL.RESTRICTED;
            if (hasPurchaseOrdersEntitlement && hasPurchaseOrdersListPermission && job !== null && this.state.jobPurchaseOrdersData === null && !(window.USING_PUBLIC_URL === true)) {
                let jobPurchaseOrders = []

                let jobPurchaseOrdersEndpoint
                let jobPurchaseOrdersResponse
                let jobPurchaseOrdersResponseJSON = {
                    next: `${DjangoUrls["purchase-orders:api-purchase-orders-list"](window.MARKETPLACE_ENTITY_SLUG)}?job=${job.id}`,
                    previous: null,
                    results: [],
                }

                while (jobPurchaseOrdersResponseJSON.next !== null) {
                    jobPurchaseOrdersEndpoint = jobPurchaseOrdersResponseJSON.next
                    jobPurchaseOrdersResponse = await fetch(jobPurchaseOrdersEndpoint)
                    jobPurchaseOrdersResponseJSON = await jobPurchaseOrdersResponse.json()
                    jobPurchaseOrders.push(...jobPurchaseOrdersResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.jobPurchaseOrdersData = jobPurchaseOrders
                    return updatedState
                })
            }

            const hasEstimatesEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_estimates_enabled;
            const hasEstimatesListPermission = !(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.estimates_list_permission >= PERMISSION_LEVEL.RESTRICTED;
            if (hasEstimatesEntitlement && hasEstimatesListPermission && job !== null && job.is_job_walk && this.state.jobChildEstimatesData === null && !(window.USING_PUBLIC_URL === true)) {
                let jobChildEstimates = []

                let jobChildEstimateEndpoint
                let jobChildEstimateResponse
                let jobChildEstimateResponseJSON = {
                    next: `${DjangoUrls["estimates:api-estimates-list"](window.MARKETPLACE_ENTITY_SLUG)}?job_walk=${job.id}`,
                    previous: null,
                    results: [],
                }

                while (jobChildEstimateResponseJSON.next !== null) {
                    jobChildEstimateEndpoint = jobChildEstimateResponseJSON.next
                    jobChildEstimateResponse = await fetch(jobChildEstimateEndpoint)
                    jobChildEstimateResponseJSON = await jobChildEstimateResponse.json()
                    jobChildEstimates.push(...jobChildEstimateResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.jobChildEstimatesData = jobChildEstimates
                    return updatedState
                })
            }

            const hasJobsEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_jobs_enabled;
            const hasJobsListPermission = !(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.jobs_list_permission >= PERMISSION_LEVEL.RESTRICTED;
            if (hasJobsEntitlement && hasJobsListPermission && job !== null && !job.is_job_walk && this.state.jobChildCallbacksData === null && !(window.USING_PUBLIC_URL === true)) {
                let jobChildCallbacks = []

                let jobChildCallbacksEndpoint
                let jobChildCallbacksResponse
                let jobChildCallbacksResponseJSON = {
                    next: `${DjangoUrls["jobs:api-jobs-list"](window.MARKETPLACE_ENTITY_SLUG)}?callback_to=${job.id}`,
                    previous: null,
                    results: [],
                }

                while (jobChildCallbacksResponseJSON.next !== null) {
                    jobChildCallbacksEndpoint = jobChildCallbacksResponseJSON.next
                    jobChildCallbacksResponse = await fetch(jobChildCallbacksEndpoint)
                    jobChildCallbacksResponseJSON = await jobChildCallbacksResponse.json()
                    jobChildCallbacks.push(...jobChildCallbacksResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.jobChildCallbacksData = jobChildCallbacks
                    return updatedState
                })
            }

            const hasInvoicesEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_invoices_enabled;
            const hasInvoicesListPermission = !(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.invoices_list_permission >= PERMISSION_LEVEL.RESTRICTED;
            if (hasInvoicesEntitlement && hasInvoicesListPermission && job !== null && !job.is_job_walk && this.state.jobChildInvoicesData === null && !(window.USING_PUBLIC_URL === true)) {
                let jobChildInvoices = []

                let jobChildInvoicesEndpoint
                let jobChildInvoicesResponse
                let jobChildInvoicesResponseJSON = {
                    next: `${DjangoUrls["invoices:api-invoices-list"](window.MARKETPLACE_ENTITY_SLUG)}?job=${job.id}`,
                    previous: null,
                    results: [],
                }

                while (jobChildInvoicesResponseJSON.next !== null) {
                    jobChildInvoicesEndpoint = jobChildInvoicesResponseJSON.next
                    jobChildInvoicesResponse = await fetch(jobChildInvoicesEndpoint)
                    jobChildInvoicesResponseJSON = await jobChildInvoicesResponse.json()
                    jobChildInvoices.push(...jobChildInvoicesResponseJSON.results)
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.jobChildInvoicesData = jobChildInvoices
                    return updatedState
                })
            }
        }

        if (this.state.clockInStatus === null && !(window.USING_PUBLIC_URL === true)) {
            const clockInStatusEndpoint = DjangoUrls["timesheets:api-timesheets-check-clock-status"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
            const clockInStatusResponse = await fetch(clockInStatusEndpoint)
            const clockInStatus = await clockInStatusResponse.json()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.clockInStatus = clockInStatus
                return updatedState
            })
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        } else {
            // If the client asks for the send job page directly, oblidge
            const desiredMode = new URLSearchParams(document.location.search).get("mode") || ""
            if (desiredMode === "send-job") {
                this.handleActionRequest("JOB_SEND")
            }
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (newFormMode === PAGE_MODES.ADD_CONTACT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].attached_to = "external_client"
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_ephemeral = false
                }
                else if (newFormMode === PAGE_MODES.SEND_JOB && this.defaultClientMessage) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].client_message = this.defaultClientMessage
                }
            }

            // Set the only assigned technician as the default if applicable
            if (this.workingTechnicianOptions.length === 1) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].assigned_technicians = [this.workingTechnicianOptions[0].value]
            }

            if (newFormMode === PAGE_MODES.RESCHEDULE_JOB) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].details = state.jobData.details
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].dispatcher_notes = state.jobData.dispatcher_notes
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    updateSubStatusSelection = (selectedSubStatus) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedSubStatus !== null) {
                updatedState.startData.substatus = selectedSubStatus.id
                updatedState.resumeData.substatus = selectedSubStatus.id
                updatedState.pauseData.substatus = selectedSubStatus.id
                updatedState.selectedSubStatus = selectedSubStatus
            }
            else {
                updatedState.startData.substatus = null
                updatedState.resumeData.substatus = null
                updatedState.pauseData.substatus = null
                updatedState.selectedSubStatus = null
            }

            return updatedState
        })
    }

    updateSendPhoneSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendPhoneContacts = selectedContacts
            updatedState.sendData.phone_contacts = selectedContacts

            return updatedState
        })
    }

    updateSendEmailSelection = (selectedContacts) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedSendEmailContacts = selectedContacts
            updatedState.sendData.email_contacts = selectedContacts

            return updatedState
        })
    }


    // Update Attachments

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.jobData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    updateAttachmentsSubmit = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-update-attachments"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "PUT"
        const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)

        const dataName = "jobData"
        const submittingName = "submittingUpdateAttachments"
        const errorDictName = "updateAttachments"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" attachments updated`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job attachments could not be updated",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Cancel Job

    cancelJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-cancel"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        const dataName = "cancelData"
        const submittingName = "submittingCancelData"
        const errorDictName = "cancel"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" cancelled`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
                },
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(this.backDestination || successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be cancelled",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Start Job

    startJob = async (toastTitleSuffix) => {
        const endpoint = DjangoUrls["jobs:api-jobs-start"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)

        const dataName = "startData"
        const submittingName = "submittingStartData"
        const errorDictName = "start"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" ${toastTitleSuffix}`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        if (window.SHOW_JOB_ACTION_CONFIRMATIONS && !(window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST)) {
            document.querySelectorAll("#message_modal_start .modal__close .button").forEach(button => button.style.display = "none")
            document.querySelector("#message_modal_start .modal__close .spinner-centered").style.display = "block"
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Job could not be ${toastTitleSuffix}`,
            })
            const startModal = document.querySelector("#message_modal_start .modal__close")
            if (startModal) {
                startModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Pause Job

    quickPauseJob = async (editNext) => {
        const endpoint = DjangoUrls["jobs:api-jobs-pause"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const successUrl = editNext ? DjangoUrls["jobs:jobs-summary"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id) : DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        this.pauseJob(endpoint, successUrl, "quick-paused")
    }

    placeJobOnHold = async (editNext) => {
        const endpoint = DjangoUrls["jobs:api-jobs-place-on-hold"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const successUrl = editNext ? DjangoUrls["jobs:jobs-summary"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id) : DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        this.pauseJob(endpoint,successUrl, "placed on hold")
    }

    pauseJob = async (endpoint, successUrl, toastTitleSuffix) => {
        const endpointMethod = "POST"

        const dataName = "pauseData"
        const submittingName = "submittingPauseData"
        const errorDictName = "pause"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" ${toastTitleSuffix}`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Job could not be ${toastTitleSuffix}`
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Schedule Job

    scheduleJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-schedule"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        const dataName = "scheduleData"
        const submittingName = "submittingScheduleData"
        const errorDictName = "schedule"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" scheduled`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
                },
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(this.backDestination || successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be scheduled",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Reschedule Job

    rescheduleJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-reschedule"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        const dataName = "rescheduleData"
        const submittingName = "submittingRescheduleData"
        const errorDictName = "reschedule"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" rescheduled`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
                },
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(this.backDestination || successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be rescheduled",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

     // Unschedule Job

     unscheduleJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-unschedule"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        const dataName = "unscheduleData"
        const submittingName = "submittingUnscheduleData"
        const errorDictName = "unschedule"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" unscheduled`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
                },
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(this.backDestination || successUrl)
        }
        document.querySelectorAll("#message_modal_unschedule .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_unschedule .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be unscheduled",
            })
            const unscheduleModal = document.querySelector("#message_modal_unschedule .modal__close")
            if (unscheduleModal) {
                unscheduleModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Resume Job

    resumeJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-resume"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)

        const dataName = "resumeData"
        const submittingName = "submittingResumeData"
        const errorDictName = "resume"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" resumed`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        if (window.SHOW_JOB_ACTION_CONFIRMATIONS && !(window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST)) {
            document.querySelectorAll("#message_modal_resume .modal__close .button").forEach(button => button.style.display = "none")
            document.querySelector("#message_modal_resume .modal__close .spinner-centered").style.display = "block"
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be resumed",
            })
            const resumeModal = document.querySelector("#message_modal_resume .modal__close")
            if (resumeModal) {
                resumeModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Mark as Estimated

    markJobAsEstimated = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-mark-as-estimated"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        const dataName = "markAsEstimatedData"
        const submittingName = "submittingMarkAsEstimatedData"
        const errorDictName = "markAsEstimated"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" marked as estimated`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
                },
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(this.backDestination || successUrl)
        }

        document.querySelectorAll("#message_modal_mark_as_estimated .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_estimated .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be marked as estimated",
            })
            const markAsEstimatedModal = document.querySelector("#message_modal_mark_as_estimated .modal__close")
            if (markAsEstimatedModal) {
                markAsEstimatedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Mark Job as Invoiced

    markJobAsInvoiced = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-mark-as-invoiced"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        const dataName = "markAsInvoicedData"
        const submittingName = "submittingMarkAsInvoicedData"
        const errorDictName = "markAsInvoiced"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" marked as invoiced`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
                },
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(this.backDestination || successUrl)
        }

        document.querySelectorAll("#message_modal_mark_as_invoiced .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_invoiced .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            const markAsInvoicedModal = document.querySelector("#message_modal_mark_as_invoiced .modal__close")
            if (markAsInvoicedModal) {
                markAsInvoicedModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be marked as invoiced",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }


    clockInToJOb = async () => {
        const endpoint = DjangoUrls["timesheets:api-timesheets-clock-in"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"

        const dataName = "clockData"
        const submittingName = "submittingClockData"
        const errorDictName = "clock"

        const onSuccess = (data) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Clocked in to Job "${this.state.jobData.custom_id || `Job ${this.state.jobData.id}`}`,
            })
            this.setState({clockInStatus: data})
        }

        const setErrors = (fieldName, message, errorDict) => {
            let jobID = null
            let description = null
            let cta

            if (message.startsWith("You have an open timesheet entry")) {
                let jobDisplay = message.split("You have an open timesheet entry on").pop().split(".")[0]
                jobID = message.split("[").pop().split("]")[0]
                description = `${message.split(".")[0]}. Please clock out before clocking in to this job.`

                cta = {
                    children: `View ${jobDisplay}`,
                    destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, jobID)
                }
            }
            else {
                description = message
            }

            this.addToastToQueue({
                type: "error",
                size: "lg",
                title: `Could not clock in to job`,
                subtitle: description ? description : undefined,
                cta: cta,
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, undefined, undefined, setErrors)
    }

    clockOutOfJOb = async () => {
        const endpoint = DjangoUrls["timesheets:api-timesheets-clock-out"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"

        const dataName = "clockData"
        const submittingName = "submittingClockData"
        const errorDictName = "clock"

        const onSuccess = (data) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Clocked out of ${this.state.jobData.custom_id || `Job ${this.state.jobData.id}`}`,
            })
            this.setState({clockInStatus: data})
        }

        const setErrors = (fieldName, message, errorDict) => {
            this.addToastToQueue({
                type: "error",
                size: "lg",
                title: `Could not clock out of job`,
                subtitle: message,
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, undefined, undefined, setErrors)
    }


    // Send Job

    submitSendData = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-send"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)

        const dataName = "sendData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" summary sent`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.send_via_sms = state.sendViaSMS
            data.send_via_email = state.sendViaEmail
            data.phone_contacts = data.phone_contacts || []
            data.email_contacts = data.email_contacts || []

            return data
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job summary could not be sent",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }


    // Crud Contact

    createContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

        const contactData = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]]

        const { isValid, errors } = validateSendContact(
            contactData,
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            let endpoint

            if (attachedTo === "external_client") {
                endpoint = DjangoUrls["clients:api-clients-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.external_client.id)
            }
            else {
                endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.external_client.id, this.state.jobData.service_location.id)
            }

            const endpointMethod = "POST"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                // Set the new contact in state and then grab that state to use in a new form
                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_JOB
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_JOB
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: contact.is_ephemeral ? "lg" : "md",
                    title: `Contact ${contact.is_ephemeral ? "added" : "created"}`,
                    subtitle: `This contact will not be saved to the contact list`
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    updateContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef
        const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

        const { isValid, errors } = validateSendContact(
            this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]],
            [populationRef === "updateSendPhoneSelection" ? "phone" : "email"]
        )

        if (isValid) {
            let endpoint

            if (attachedTo === "external_client") {
                endpoint = DjangoUrls["clients:api-clients-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.external_client.id, this.state[dataRef].id)
            }
            else {
                endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.service_location.external_client.id, this.state.jobData.service_location.id, this.state[dataRef].id)
            }

            const endpointMethod = "PUT"

            const onSuccess = (contact) => {
                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_JOB
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.phone)) {
                        const newContacts = [...this.state.selectedSendPhoneContacts, contact]
                        destinationData.phone_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_JOB
                    destinationData = deepcopy()(this.state.sendData)

                    if (valueIsDefined(contact.email)) {
                        const newContacts = [...this.state.selectedSendEmailContacts, contact]
                        destinationData.email_contacts = newContacts
                        this[populationRef](newContacts)
                    }
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                this.addToastToQueue({
                    type: "success",
                    size: "md",
                    title: "Contact updated",
                })
            }
            const onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: "Contact could not be updated",
                })
            }

            this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
        }
        else {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Contact could not be updated",
            })
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = errors
                return updatedState
            })
        }
    }

    createUpdateContact = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "contactData"
        const submittingName = "submittingContact"
        const errorDictName = "contact"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message.endsWith("name, phone, phone_extension, email must make a unique set.")) {
                errorDict["non_field_error"] = "A contact with these details already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "JOB_SEND":
                this.switchToSecondaryForm(PAGE_MODES.SEND_JOB, null, null)
                break
            case "TOGGLE_SEND_VIA_SMS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaSMS = !state.sendViaSMS
                    return updatedState
                })
                break
            case "TOGGLE_SEND_VIA_EMAIL":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.sendViaEmail = !state.sendViaEmail
                    return updatedState
                })
                break
            case "JOB_SEND_SUBMIT":
                if (!(this.state.sendViaSMS || this.state.sendViaEmail)) {
                    // Check that at least one method is selected
                    this.setState((state, props) => {
                        let updatedState = state
                        updatedState.errors.send.deliveryMethod = "Please select at least one delivery method."
                        return updatedState
                    })
                }
                else {
                    this.submitSendData()
                }
                break
            case "JOB_CANCEL":
                this.switchToSecondaryForm(PAGE_MODES.CANCEL_JOB, null, null)
                break
            case "JOB_CANCEL_SUBMIT":
                this.cancelJob()
                break
            case "JOB_EDIT":
                location.assign(DjangoUrls["jobs:jobs-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id))
                break
            case "JOB_START":
                if (window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST) {
                    this.switchToSecondaryForm(PAGE_MODES.START_JOB, null, null)
                } else {
                    if (this.state.jobData.initial_started_time === null) {
                        if (window.SHOW_JOB_ACTION_CONFIRMATIONS) {
                            window.startJob = this.startJob
                            document.querySelector("#message_modal_start").style.display = ""
                            window.MicroModal.show("message_modal_start")
                        }
                        else {
                            this.startJob("started")
                        }
                    }
                    else {
                        if (window.SHOW_JOB_ACTION_CONFIRMATIONS) {
                            window.resumeJob = this.startJob
                            document.querySelector("#message_modal_resume").style.display = ""
                            window.MicroModal.show("message_modal_resume")
                        }
                        else {
                            this.startJob("resumed")
                        }
                    }
                }
                break
            case "JOB_START_SUBMIT":
                if (this.state.jobData.initial_started_time === null) {
                    this.startJob("started")
                }
                else {
                    this.startJob("resumed")
                }
                break
            case "JOB_PAUSE":
                this.switchToSecondaryForm(PAGE_MODES.PAUSE_JOB, null, null)
                break
            case "JOB_PAUSE_SUBMIT":
                if (this.state.pauseData.place_on_hold) {
                    this.placeJobOnHold(false)
                }
                else {
                    this.quickPauseJob(false)
                }
                break
            case "JOB_PAUSE_SUBMIT_AND_EDIT":
                if (this.state.pauseData.place_on_hold) {
                    this.placeJobOnHold(true)
                }
                else {
                    this.quickPauseJob(true)
                }
                break
            case "JOB_RESUME":
                if (window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST){
                    this.switchToSecondaryForm(PAGE_MODES.RESUME_JOB, null, null)
                } else {
                    if (window.SHOW_JOB_ACTION_CONFIRMATIONS) {
                        window.resumeJob = this.resumeJob
                        document.querySelector("#message_modal_resume").style.display = ""
                        window.MicroModal.show("message_modal_resume")
                    }
                    else {
                        this.resumeJob()
                    }
                }
                break
            case "JOB_RESUME_FROM_COMPLETED":
                if (window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST){
                    this.switchToSecondaryForm(PAGE_MODES.RESUME_JOB, null, null)
                } else {
                    if (window.SHOW_JOB_ACTION_CONFIRMATIONS) {
                        window.resumeJob = this.resumeJob
                        document.querySelector("#message_modal_resume_from_completed").style.display = ""
                        window.MicroModal.show("message_modal_resume_from_completed")
                    }
                    else {
                        this.resumeJob()
                    }
                }
                break
            case "JOB_RESUME_SUBMIT":
                this.resumeJob()
                break
            case "JOB_SCHEDULE":
                this.switchToSecondaryForm(PAGE_MODES.SCHEDULE_JOB, null, null)
                break
            case "JOB_SCHEDULE_SUBMIT":
                this.scheduleJob()
                break
            case "JOB_RESCHEDULE":
                this.switchToSecondaryForm(PAGE_MODES.RESCHEDULE_JOB, null, null)
                break
            case "JOB_RESCHEDULE_SUBMIT":
                this.rescheduleJob()
                break
            case "JOB_UNSCHEDULE":
                if (window.SHOW_JOB_ACTION_CONFIRMATIONS) {
                    window.unscheduleJob = this.unscheduleJob
                    document.querySelector("#message_modal_unschedule").style.display = ""
                    window.MicroModal.show("message_modal_unschedule")
                }
                else {
                    this.unscheduleJob()
                }
                break
            case "JOB_CLOCK_IN":
                this.clockInToJOb()
                break
            case "JOB_CLOCK_OUT":
                this.clockOutOfJOb()
                break
            case "JOB_EDIT_DETAILS":
                location.assign(DjangoUrls["jobs:jobs-details-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id))
                break
            case "JOB_EDIT_SUMMARY":
                location.assign(DjangoUrls["jobs:jobs-summary"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id))
                break
            case "EQUIPMENT_EDIT":
                location.assign(DjangoUrls["jobs:jobs-equipment-summary"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id) + `?type=standalone`)
                break
            case "JOB_COMPLETE":
                if (this.state.jobData.equipment_expected) {
                    location.assign(DjangoUrls["jobs:jobs-equipment-summary"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id))
                }
                else {
                    location.assign(DjangoUrls["jobs:jobs-complete"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id))
                }
                break
            case "JOB_CREATE_ESTIMATE":
                location.assign(`${DjangoUrls["estimates:estimates-create"](window.MARKETPLACE_ENTITY_SLUG)}?from_job=${this.state.jobData.id}`)
                break
            case "JOB_MARK_AS_ESTIMATED":
                window.markJobAsEstimated = this.markJobAsEstimated
                document.querySelector("#message_modal_mark_as_estimated").style.display = ""
                window.MicroModal.show("message_modal_mark_as_estimated")
                break
            case "JOB_CREATE_INVOICE":
                location.assign(`${DjangoUrls["invoices:invoices-create"](window.MARKETPLACE_ENTITY_SLUG)}?from_job=${this.state.jobData.id}`)
                break
            case "JOB_MARK_AS_INVOICED":
                window.markJobAsInvoiced = this.markJobAsInvoiced
                document.querySelector("#message_modal_mark_as_invoiced").style.display = ""
                window.MicroModal.show("message_modal_mark_as_invoiced")
                break
            case "JOB_CREATE_CALLBACK":
                location.assign(DjangoUrls["jobs:jobs-create"](window.MARKETPLACE_ENTITY_SLUG) + `?callback_to=${this.state.jobData.id}`)
                break
            case "JOB_DOWNLOAD_PDF":
                location.assign(window.PUBLIC_PDF_URL)
                break
            case "CONTACT_CREATE":
                this.createContact()
                break
            case "CONTACT_UPDATE":
                const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

                if (this.state[dataRef].is_ephemeral) {
                    this.createContact()
                }
                else {
                    this.updateContact()
                }
                break
            case "CONTACT_CANCEL_CREATE":
            case "CONTACT_CANCEL_EDITS":
                const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

                let destinationMode = null
                let destinationData = null

                if (populationRef === "updateSendPhoneSelection") {
                    destinationMode = PAGE_MODES.SEND_JOB
                    destinationData = deepcopy()(this.state.sendData)
                }
                else if (populationRef === "updateSendEmailSelection") {
                    destinationMode = PAGE_MODES.SEND_JOB
                    destinationData = deepcopy()(this.state.sendData)
                }

                this.switchToPrimaryForm()
                this.switchToSecondaryForm(destinationMode, destinationData, null)
                break
            case "UPLOAD_ATTACHMENTS":
                this.switchToSecondaryForm(PAGE_MODES.UPDATE_ATTACHMENTS, null, null)
                break
            case "JOB_UPLOAD_ATTACHMENTS":
                this.updateAttachmentsSubmit()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.jobData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <JobDetailsCard
                    job={this.state.jobData}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    childEstimates={this.state.jobChildEstimatesData || []}
                    childCallbacks={this.state.jobChildCallbacksData || []}
                    childInvoices={this.state.jobChildInvoicesData || []}
                    purchaseOrders={this.state.jobPurchaseOrdersData || []}
                    clockInStatus={this.state.clockInStatus}
                    returnScroll={this.state.returnScroll}
                    addToastToQueue={this.addToastToQueue}
                ></JobDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.SEND_JOB) {
                return <JobSendForm
                    job={this.state.jobData}
                    sendData={this.state.sendData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    submitting={this.state.submittingSend}
                    errors={this.state.errors.send}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("sendData", fieldName, fieldValue)}
                    updateSendPhoneSelection={this.updateSendPhoneSelection}
                    updateSendEmailSelection={this.updateSendEmailSelection}
                    selectedSendPhoneContacts={this.state.selectedSendPhoneContacts}
                    selectedSendEmailContacts={this.state.selectedSendEmailContacts}
                    sendViaSMS={this.state.sendViaSMS}
                    sendViaEmail={this.state.sendViaEmail}
                    returnScroll={0}
                ></JobSendForm>
            }
            else if (this.state.mode === PAGE_MODES.CANCEL_JOB) {
                return <JobCancelForm
                    job={this.state.jobData}
                    cancelData={this.state.cancelData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingCancel}
                    errors={this.state.errors.cancel}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("cancelData", fieldName, fieldValue)}
                    inventoryUsed={this.state.inventoryUsed}
                    returnScroll={0}
                ></JobCancelForm>
            }
            else if (this.state.mode === PAGE_MODES.PAUSE_JOB) {
                return <JobPauseForm
                    job={this.state.jobData}
                    pauseData={this.state.pauseData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingPause}
                    errors={this.state.errors.pause}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("pauseData", fieldName, fieldValue)}
                    updateSubStatusSelection={this.updateSubStatusSelection}
                    selectedSubStatus={this.state.selectedSubStatus}
                    returnScroll={0}
                ></JobPauseForm>
            }
            else if (this.state.mode === PAGE_MODES.START_JOB) {
                return <JobStartForm
                    job={this.state.jobData}
                    mode={this.state.mode}
                    startData={this.state.startData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingStart}
                    errors={this.state.errors.start}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("startData", fieldName, fieldValue)}
                    updateSubStatusSelection={this.updateSubStatusSelection}
                    selectedSubStatus={this.state.selectedSubStatus}
                    returnScroll={0}
                ></JobStartForm>
            }
            else if (this.state.mode === PAGE_MODES.RESUME_JOB) {
                return <JobStartForm
                    job={this.state.jobData}
                    mode={this.state.mode}
                    startData={this.state.resumeData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingResume}
                    errors={this.state.errors.resume}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("resumeData", fieldName, fieldValue)}
                    updateSubStatusSelection={this.updateSubStatusSelection}
                    selectedSubStatus={this.state.selectedSubStatus}
                    returnScroll={0}
                ></JobStartForm>
            }
            else if (this.state.mode === PAGE_MODES.RESCHEDULE_JOB) {
                return <JobRescheduleForm
                    job={this.state.jobData}
                    rescheduleData={this.state.rescheduleData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingReschedule}
                    errors={this.state.errors.reschedule}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("rescheduleData", fieldName, fieldValue)}
                    workingTechnicianOptions={this.workingTechnicianOptions}
                    returnScroll={0}
                ></JobRescheduleForm>
            }
            else if (this.state.mode === PAGE_MODES.SCHEDULE_JOB) {
                return <JobScheduleForm
                    job={this.state.jobData}
                    scheduleData={this.state.scheduleData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingSchedule}
                    errors={this.state.errors.schedule}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("scheduleData", fieldName, fieldValue)}
                    workingTechnicianOptions={this.workingTechnicianOptions}
                    returnScroll={0}
                ></JobScheduleForm>
            }
            else if (this.state.mode === PAGE_MODES.UPDATE_ATTACHMENTS) {
                return <JobAttachmentForm
                    job={this.state.jobData}
                    updateAttachmentsData={this.state.attachments}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingUpdateAttachments}
                    errors={this.state.errors.updateAttachments}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("updateAttachmentsData", fieldName, fieldValue)}
                    returnScroll={0}
                    fileStackAPIKey={this.state.fileStackAPIKey}
                    fileStackPolicy={this.state.fileStackPolicy}
                    fileStackSignature={this.state.fileStackSignature}
                    updateAttachments={this.updateAttachments}
                ></JobAttachmentForm>
            }
            else if (CONTACT_PAGE_MODES.includes(this.state.mode)) {
                return <ContactForm
                    mode={this.state.mode}
                    submitting={this.state.submittingContact}
                    contact={this.state.contactData}
                    errors={this.state.errors.contact}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("contactData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ContactForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default JobDetailsContainer;
