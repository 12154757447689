import { TableViewConfig } from "@organisms/Table/Table.types"

export type TableViewConfigType = {
    version?: string
    table: {
        [key: string]: TableViewConfig | undefined
    }
}

const defaultTableViewConfig: TableViewConfigType = {
    /*
        Versioning Scheme:
        - Major: Breaking changes.
        - Minor: Column additions/removals.
        - Patch: Updates to the default config.
        Note: Patch updates are only applied to users without modified view configs, serving as a new default without data migration.
    */
    version: "1.16.0",
    table: {
        estimates_table__open_all_open: {
            columnOrder: [
                "id",
                "client_name",
                "state_label",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        estimates_table__open_pending: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "date_due",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        estimates_table__open_sent: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "date_due",
                "last_sent_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        estimates_table__open_approved: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "down_payment_status",
                "last_approved_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        estimates_table__closed_all_closed: {
            columnOrder: [
                "id",
                "client_name",
                "state_label",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "down_payment_status",
                "last_status_transition_time",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        estimates_table__closed_won: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "down_payment_status",
                "last_won_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        estimates_table__closed_lost: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "date_due",
                "last_lost_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        estimates_table__closed_cancelled: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "total",
                "date_due",
                "last_cancelled_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        jobs_table__open_all_open: {
            columnOrder: [
                "id",
                "service_name",
                "state_label",
                "status_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_status_transition_time",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "estimated_arrival_time",
                    desc: false,
                },
            ],
        },
        jobs_table__open_pending: {
            columnOrder: [
                "id",
                "service_name",
                "status_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "id",
                    desc: true,
                },
            ],
        },
        jobs_table__open_scheduled: {
            columnOrder: [
                "id",
                "service_name",
                "status_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_scheduled_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "estimated_arrival_time",
                    desc: false,
                },
            ],
        },
        jobs_table__open_in_progress: {
            columnOrder: [
                "id",
                "service_name",
                "status_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_status_transition_time",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "estimated_arrival_time",
                    desc: false,
                },
            ],
        },
        jobs_table__open_on_hold: {
            columnOrder: [
                "id",
                "service_name",
                "status_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_on_hold_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "estimated_arrival_time",
                    desc: false,
                },
            ],
        },
        jobs_table__open_completed: {
            columnOrder: [
                "id",
                "service_name",
                "status_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_completed_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "estimated_arrival_time",
                    desc: true,
                },
            ],
        },
        jobs_table__closed_all_closed: {
            columnOrder: [
                "id",
                "service_name",
                "state_label",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_status_transition_time",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "id",
                    desc: true,
                },
            ],
        },
        jobs_table__closed_finalized: {
            columnOrder: [
                "id",
                "service_name",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_finalized_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "last_finalized_at",
                    desc: true,
                },
            ],
        },
        jobs_table__closed_cancelled: {
            columnOrder: [
                "id",
                "service_name",
                "labels",
                "job_type",
                "priority",
                "assigned_technicians",
                "date_received",
                "client_name",
                "service_location_name",
                "service_location_address",
                "estimated_arrival_time",
                "estimated_duration",
                "last_cancelled_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "service_name"],
            },
            sorting: [
                {
                    id: "estimated_arrival_time",
                    desc: true,
                },
            ],
        },
        invoices_table__open_all_open: {
            columnOrder: [
                "id",
                "client_name",
                "state_label",
                "status_label",
                "amount_due",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        invoices_table__open_pending: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "amount_due",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        invoices_table__open_outstanding: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "amount_due",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_sent_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        invoices_table__open_overdue: {
            columnOrder: [
                "id",
                "client_name",
                "status_label",
                "amount_due",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_sent_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        invoices_table__closed_all_closed: {
            columnOrder: [
                "id",
                "client_name",
                "state_label",
                "total",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        invoices_table__closed_paid: {
            columnOrder: [
                "id",
                "client_name",
                "total",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_paid_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        invoices_table__closed_voided: {
            columnOrder: [
                "id",
                "client_name",
                "total",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_voided_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        invoices_table__closed_forgiven: {
            columnOrder: [
                "id",
                "client_name",
                "total",
                "service_name",
                "service_location_name",
                "service_location_address",
                "labels",
                "date_due",
                "last_forgiven_at",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "client_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        purchase_orders_table__open_all_open: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "item_count",
                "subtotal",
                "shipping",
                "tracking_number",
                "associated_jobs",
                "last_status_transition_time",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__open_pending: {
            columnOrder: [
                "id",
                "vendor_name",
                "status_label",
                "labels",
                "item_count",
                "subtotal",
                "preferred_shipping",
                "associated_jobs",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__open_open: {
            columnOrder: [
                "id",
                "vendor_name",
                "status_label",
                "labels",
                "item_count",
                "subtotal",
                "preferred_shipping",
                "date_issued",
                "associated_jobs",
                "last_status_transition_time",
                "last_modified_at",
                "last_viewed",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__open_in_transit: {
            columnOrder: [
                "id",
                "vendor_name",
                "status_label",
                "labels",
                "item_count",
                "subtotal",
                "estimated_arrival_time",
                "shipping",
                "tracking_number",
                "associated_jobs",
                "last_in_transit_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__open_delivered: {
            columnOrder: [
                "id",
                "vendor_name",
                "status_label",
                "labels",
                "item_count",
                "subtotal",
                "shipping",
                "tracking_number",
                "associated_jobs",
                "last_delivered_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__open_stocked: {
            columnOrder: [
                "id",
                "vendor_name",
                "status_label",
                "labels",
                "item_count",
                "subtotal",
                "payment_terms",
                "associated_jobs",
                "last_stocked_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__closed_all_closed: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "labels",
                "item_count",
                "subtotal",
                "associated_jobs",
                "last_status_transition_time",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__closed_paid: {
            columnOrder: [
                "id",
                "vendor_name",
                "labels",
                "item_count",
                "subtotal",
                "associated_jobs",
                "last_paid_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        purchase_orders_table__closed_cancelled: {
            columnOrder: [
                "id",
                "vendor_name",
                "labels",
                "item_count",
                "subtotal",
                "associated_jobs",
                "last_cancelled_at",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_issued",
                    desc: false,
                },
            ],
        },
        bills_table__open_all_open: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        bills_table__open_pending: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        bills_table__open_outstanding: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        bills_table__open_overdue: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: false,
                },
            ],
        },
        bills_table__closed_all_closed: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        bills_table__closed_paid: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        bills_table__closed_voided: {
            columnOrder: [
                "id",
                "vendor_name",
                "state_label",
                "status_label",
                "labels",
                "subtotal",
                "date_due",
                "last_status_transition_time",
                "last_modified_at",
                "last_received_at",
                "last_paid_at",
                "last_voided_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["id", "vendor_name"],
            },
            sorting: [
                {
                    id: "date_due",
                    desc: true,
                },
            ],
        },
        clients_table__active_all_active: {
            columnOrder: [
                "name",
                "client_type_label",
                "labels",
                "service_locations_display",
                "default_invoice_net",
                "credit_limit",
                "outstanding_balance",
                "current_estimates_count",
                "current_jobs_count",
                "current_invoices_count",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["name", "client_type_label"],
            },
            sorting: [
                {
                    id: "name",
                    desc: false,
                },
            ],
        },
        clients_table__inactive_all_inactive: {
            columnOrder: [
                "name",
                "client_type_label",
                "labels",
                "service_locations_display",
                "default_invoice_net",
                "credit_limit",
                "outstanding_balance",
                "current_estimates_count",
                "current_jobs_count",
                "current_invoices_count",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["name", "client_type_label"],
            },
            sorting: [
                {
                    id: "name",
                    desc: false,
                },
            ],
        },
        vendors_table__active_all_active: {
            columnOrder: [
                "name",
                "account_id",
                "billing_address_formatted",
                "labels",
                "payment_terms",
                "outstanding_balance",
                "current_purchase_order_count",
                "website",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["name"],
            },
            sorting: [
                {
                    id: "name",
                    desc: false,
                },
            ],
        },
        vendors_table__inactive_all_inactive: {
            columnOrder: [
                "name",
                "account_id",
                "billing_address_formatted",
                "labels",
                "payment_terms",
                "outstanding_balance",
                "current_purchase_order_count",
                "website",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["name"],
            },
            sorting: [
                {
                    id: "name",
                    desc: false,
                },
            ],
        },
        job_series_table__open_all_open: {
            columnOrder: [
                "service_name",
                "client_name",
                "service_location_name",
                "service_location_address",
                "recurrence_start",
                "recurrence_end",
                "recurrence",
                "open_jobs_count",
                "created_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: [],
            },
            sorting: [
                {
                    id: "recurrence_start",
                    desc: true,
                },
            ],
        },
        job_series_table__closed_all_closed: {
            columnOrder: [
                "service_name",
                "client_name",
                "service_location_name",
                "service_location_address",
                "recurrence_start",
                "recurrence_end",
                "recurrence",
                "open_jobs_count",
                "created_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: [],
            },
            sorting: [
                {
                    id: "recurrence_start",
                    desc: false,
                },
            ],
        },
        pricebook_items_services_table__active_all_active: {
            columnOrder: [
                "description",
                "status_label",
                "summary_template",
                "cost",
                "default_price",
                "default_job_duration",
                "labels",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "confirmed",
                    desc: false,
                },
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_services_table__active_review: {
            columnOrder: [
                "description",
                "status_label",
                "summary_template",
                "cost",
                "default_price",
                "default_job_duration",
                "labels",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_services_table__active_confirmed: {
            columnOrder: [
                "description",
                "summary_template",
                "cost",
                "default_price",
                "default_job_duration",
                "labels",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_services_table__inactive_all_inactive: {
            columnOrder: [
                "description",
                "summary_template",
                "cost",
                "default_price",
                "default_job_duration",
                "labels",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        "pricebook_items_parts_&_materials_table__active_all_active": {
            columnOrder: [
                "description",
                "status_label",
                "labels",
                "cost",
                "default_price",
                "manufacturer",
                "part_number",
                "lowest_inventory_status",
                "inventory_level",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        "pricebook_items_parts_&_materials_table__active_review": {
            columnOrder: [
                "description",
                "labels",
                "cost",
                "default_price",
                "manufacturer",
                "part_number",
                "lowest_inventory_status",
                "inventory_level",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        "pricebook_items_parts_&_materials_table__active_confirmed": {
            columnOrder: [
                "description",
                "labels",
                "cost",
                "default_price",
                "manufacturer",
                "part_number",
                "lowest_inventory_status",
                "inventory_level",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        "pricebook_items_parts_&_materials_table__inactive_all_inactive": {
            columnOrder: [
                "description",
                "labels",
                "cost",
                "default_price",
                "manufacturer",
                "part_number",
                "lowest_inventory_status",
                "inventory_level",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        pricebook_items_miscellaneous_table__active_all_active: {
            columnOrder: [
                "description",
                "status_label",
                "labels",
                "cost",
                "default_price",
                "summary_template",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_miscellaneous_table__active_review: {
            columnOrder: ["description", "labels", "cost", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_miscellaneous_table__active_confirmed: {
            columnOrder: ["description", "labels", "cost", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_miscellaneous_table__inactive_all_inactive: {
            columnOrder: ["description", "labels", "cost", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        pricebook_items_discounts_table__active_all_active: {
            columnOrder: [
                "description",
                "status_label",
                "labels",
                "default_price",
                "summary_template",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        pricebook_items_discounts_table__active_review: {
            columnOrder: ["description", "labels", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_discounts_table__active_confirmed: {
            columnOrder: ["description", "labels", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_discounts_table__inactive_all_inactive: {
            columnOrder: ["description", "labels", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        pricebook_items_tax_rates_table__active_all_active: {
            columnOrder: [
                "description",
                "status_label",
                "labels",
                "default_price",
                "summary_template",
                "last_modified_at",
            ],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
        pricebook_items_tax_rates_table__active_review: {
            columnOrder: ["description", "labels", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_tax_rates_table__active_confirmed: {
            columnOrder: ["description", "labels", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
            ],
        },
        pricebook_items_tax_rates_table__inactive_all_inactive: {
            columnOrder: ["description", "labels", "default_price", "summary_template", "last_modified_at"],
            columnVisibility: {},
            columnPinning: {
                left: ["description"],
            },
            sorting: [
                {
                    id: "description",
                    desc: false,
                },
                {
                    id: "confirmed",
                    desc: false,
                },
            ],
        },
    },
}

export default defaultTableViewConfig
